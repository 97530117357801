(function ($) {

    var Ctrl = {
        // All pages
        'common'              : {
            init: function () {

                // JavaScript to be fired on all pages
                var navOpen = false;
                // Responsive menu toggle
                $("header").on("click", "#menu-toggle", function (e) {
                    e.preventDefault();
                    if (navOpen === false) {
                        navOpen = true;
                        $('html').addClass('nav-open');

                        $('#menu-toggle').addClass('close');

                    } else {
                        navOpen = false;
                        $('html').removeClass('nav-open');

                        $('#menu-toggle').removeClass('close');
                    }
                });

                $(".container").on("click", ".nav-overlay", function (e) {
                    e.preventDefault();
                    if (navOpen === true) {
                        navOpen = false;
                        $('html').removeClass('nav-open');

                        $('#menu-toggle i').removeClass('icon-menu-open');
                        $('#menu-toggle i').addClass('icon-menu');
                    }
                });

                //Responsive breakpoints
                $.breakpoint({
                    condition  : function () {
                        return matchMedia('only screen and (min-width:850px)').matches;
                    },
                    first_enter: function () {
                        $('section.message').prependTo('#front-boxes');
                    },
                    enter      : function () {
                        $('.container').flowtype({
                            maximum  : 1070,
                            fontRatio: 57.59
                        });
                    },
                    exit       : function () {
                        $('section.message').prependTo('#front-boxes');
                    }
                });

                $.breakpoint({
                    condition  : function () {
                        return matchMedia('only screen and (max-width:850px)').matches;
                    },
                    first_enter: function () {
                        $('ul#menu').prependTo('#offscreen-nav');

                    },
                    enter      : function () {
                        //Offiscreen Menu functions
                        $('#offscreen-nav > ul > li').each(function (i) {
                            var subMenu = false;
                            if ($(this).children(".dropdown_4columns").length) {
                                subMenu = true;
                            }
                            if (subMenu) {
                                $(this).children("a:first").after('<span>&nbsp;</span>');
                            }
                        });

                        var menuInit = false;
                        $("#offscreen-nav span").click(function (e) {
                            e.preventDefault();
                            //slide up all the link lists
                            if ($(this).hasClass('open')) {
                                $(this).removeClass('open');
                            } else {
                                $("#offscreen-nav li span").removeClass('open');
                                $(this).addClass('open');
                            }

                            if (menuInit) {
                                $("#offscreen-nav .dropdown_4columns").slideUp();
                            } else {
                                menuInit = true;
                                $("#offscreen-nav .dropdown_4columns").hide();
                            }
                            //slide down the link list below the h3 clicked - only if its closed
                            if (!$(this).next().is(":visible")) {
                                $(this).next().slideDown();
                            }
                        });
                    },
                    exit       : function () {
                        $('ul#menu').prependTo('.navbar nav');
                    }
                });

                $.breakpoint({
                    condition  : function () {
                        return matchMedia('only screen and (min-width:414px) and (max-width:850px)').matches;
                    },
                    first_enter: function () {
                        $('.container').removeAttr("style");
                    },
                    enter      : function () {
                        $('.container').flowtype({
                            minimum  : 414,
                            maximum  : 850,
                            fontRatio: 45.2
                        });
                    },
                    exit       : function () {
                        $('.container').removeAttr("style");
                        $('#offscreen-nav .dropdown_4columns').removeAttr("style");
                    }
                });

                $.breakpoint({
                    condition  : function () {
                        return matchMedia('only screen and (max-width:414px)').matches;
                    },
                    first_enter: function () {
                    },
                    enter      : function () {
                        $('.container').flowtype({
                            maximum  : 414,
                            fontRatio: 22
                        });
                    },
                    exit       : function () {
                        $('.container').removeAttr("style");
                        $('.container').flowtype({
                            minimum  : 414,
                            maximum  : 850,
                            fontRatio: 45.2
                        });
                    }
                });

                //form uniform

                // soz if this breaks everything

                // $("input.check, input.styled, textarea, select.styled").not('.guest input.check, .guest input.styled, .guest textarea, .guest select.styled, .enquire textarea').uniform({selectAutoWidth: false});
                // $("#attendee input.check, #attendee input.styled, #attendee textarea, #attendee select.styled").uniform();
                // $("#initial_fellow input.check, #initial_fellow input.styled, #initial_fellow textarea, #initial_fellow select.styled").uniform();

                //twitter
                $('.tweets-right.inspire').tweetable({username: '@INSPIRE', time: true, limit: 2, replies: false, position: 'append'});
                $('.tweets-right.amsmentoring').tweetable({username: '@AMSMentoring', time: true, limit: 1, replies: false, position: 'append'});

                //fancy box login box
                $(".fancybox").fancybox({
                    width     : 500,
                    height    : 'auto',
                    fitToView : true,
                    autoSize  : false,
                    closeClick: false,
                    helpers   : {
                        title: null
                    }
                });
                $(".iframe").fancybox({
                    type      : 'iframe',
                    fitToView : true,
                    autoSize  : true,
                    closeClick: false,
                    padding   : 0
                });

                // boxgrid images
                $('.boxgrid').imagefill();

                ///////////////////
                //tabs
                ///////////////////
                $(".tab_content").hide();
                $("ul.tabs li:first").addClass("active").show();
                $(".tab_content:first").show();

                $("ul.tabs li").click(function () {
                    $("ul.tabs li").removeClass("active");
                    $(this).addClass("active");
                    $(".tab_content").hide();
                    var activeTab = $(this).find("a").attr("href");
                    $(activeTab).fadeIn();
                    $.fn.matchHeight._update();
                    return false;
                });

                //tabs if second set
                $(".tab_content-lrg").hide();
                $("ul.tabs-lrg li:first").addClass("active").show();
                $(".tab_content-lrg:first").show();

                $("ul.tabs-lrg li").click(function () {
                    $("ul.tabs-lrg li").removeClass("active");
                    $(this).addClass("active");
                    $(".tab_content-lrg").hide();
                    var activeTab = $(this).find("a").attr("href");
                    $(activeTab).fadeIn();
                    $.fn.matchHeight._update();
                    return false;
                });

                $(".tab_content-right").hide();
                $("ul.tabs-right li:first").addClass("active").show();
                $(".tab_content-right:first").show();

                $("ul.tabs-right li").click(function () {
                    $("ul.tabs-right li").removeClass("active");
                    $(this).addClass("active");
                    $(".tab_content-right").hide();
                    var activeTab = $(this).find("a").attr("href");
                    $(activeTab).fadeIn();
                    $.fn.matchHeight._update();
                    return false;
                });

                //$(".formlist textarea.styled").jqte({
                //	format: false,
                //	color: false,
                //	fsize: false,
                //	indent: false,
                //	outdent: false,
                //	ul: false,
                //	rule: false,
                //	sub: false,
                //	strike: false,
                //	sup: false,
                //	source: false
                //});

                //Toggle other options on forms
                //Fellow Options Filter
                $("#sectionA input").change(function () {
                    if (this.checked) {
                        $('#specialisation').addClass($(this).val());
                    } else {
                        $('#specialisation').removeClass($(this).val());
                    }
                });

                $("#newFelloForm #sectionA input").each(function () {
                    if (this.checked) {
                        $('#specialisation').addClass($(this).val());
                    }
                });

                $("#B1 input").change(function () {
                    if (this.checked) {
                        $('#specialisation').addClass($(this).val());
                    } else {
                        $('#specialisation').removeClass($(this).val());
                    }
                });

                $("#newFelloForm #B1 input").each(function () {
                    if (this.checked) {
                        $('#specialisation').addClass($(this).val());
                    }
                });

                $("#sectionD input").change(function () {
                    if (this.checked) {
                        $('#specialisation').addClass($(this).val());
                    } else {
                        $('#specialisation').removeClass($(this).val());
                    }
                });

                $("#newFelloForm #sectionD input").each(function () {
                    if (this.checked) {
                        $('#specialisation').addClass($(this).val());
                    }
                });

                $("input.other").change(function () {
                    if (this.checked) {
                        $(this).parent().parent().parent().next("li").show();
                    } else {
                        $(this).parent().parent().parent().next("li").hide();
                    }
                });

                $("#newFelloForm input.other").each(function () {
                    if (this.checked) {
                        $(this).parent().next("li").show();
                    }
                });

                //Match Left and Right Column height for when modules are 'gianteee'
                $('.col').matchHeight();
            }
        },
        // Home page
        'home'                : {
            init    : function () {

                $('.slide a').imagefill();

                function makeTall() {
                    $(this).addClass("active");
                    if ($(this).parents('article').hasClass('sub')) {
                        $(this).children("div").animate({height: '95%'});
                    } else {
                        $(this).children("div").animate({height: '60%'});
                    }
                    $(this).children("div").children("span").fadeIn();
                }

                function makeShort() {
                    $(this).removeClass("active");
                    $(this).children("div").animate({height: '30%'});
                    $(this).children("div").children("span").fadeOut();
                }

                $(".slide article a").hover(makeTall, makeShort);
            },
            finalize: function () {
                $('.secondary article').matchHeight();
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // News Archive
        'news_archive'        : {
            init: function () {
                $('.article-image a').imagefill();

                //News filters
                $("#newsfilters select").on("change", function () {
                    $("#newsfilters").submit();
                });
            }
        },
        // Fellows News Archive
        'fellows_news'        : {
            init: function () {
                $('.article-image a').imagefill();
            }
        },
        // Resource Library
        'resource_library'    : {
            init: function () {
                $('.resources li').matchHeight();

                //File Filter
                viewallCheck();

                $("#categoryFilter").change(function () {
                    $.uniform.update();
                    viewallCheck();
                });

                $("#yearfilter").change(function () {
                    $.uniform.update();
                    viewallCheck();
                });

                $("a.viewall").click(function () {
                    var valId = $(this).attr('data-val');
                    $('#categoryFilter').val(valId);
                    $('#yearfilter').val('');
                    $.uniform.update();
                    filterResources();
                    viewallCheck();
                });
            }
        },
        // Fellows Directory
        'fellows_directory'   : {
            init: function () {
                //Advanced search drop down
                var menuActive = false;
                $("a.advanced").click(function (e) {
                    e.preventDefault();

                    if (!menuActive) {
                        $(".advanced-search .panel").addClass("active");

                        $(".advanced-search").find('.panel').slideToggle("slow", function () {
                            $(".lrg-search").addClass("panelactive");
                            $("a.advanced").text('Less options');
                        });

                        menuActive = true;
                    } else {
                        $(".advanced-search .panel").slideToggle("slow", function () {
                            $(".advanced-search .panel").removeClass("active");
                            $(".lrg-search").removeClass("panelactive");
                            $("a.advanced").text('More options');
                            menuActive = false;
                        });
                    }
                });

                $('.boxgrid a').imagefill();
            }
        },
        // Funding scheme page
        'funding_scheme'      : {
            init: function () {
                $('.match').matchHeight();

                $.breakpoint({
                    condition  : function () {
                        return matchMedia('only screen and (min-width:414px) and (max-width:850px)').matches;
                    },
                    first_enter: function () {
                    },
                    enter      : function () {
                        $('.funding-detail').prepend($(".funding-detail .right"));
                    },
                    exit       : function () {
                        $('.funding-detail').prepend($(".funding-detail .left"));
                    }
                });

                $.breakpoint({
                    condition  : function () {
                        return matchMedia('only screen and (max-width:414px)').matches;
                    },
                    first_enter: function () {
                    },
                    enter      : function () {
                        $('.funding-detail').prepend($(".funding-detail .right"));
                    },
                    exit       : function () {
                        $('.funding-detail').prepend($(".funding-detail .left"));
                    }
                });
            }
        },
        // Projects page
        'projects'            : {
            init: function () {
                $('.slide a').imagefill();

                /* ----------------------------------------------------
                 * Advanced search click function
                 * ---------------------------------------------------- */
                var advSearchOpen = false,
                    advSearchTog  = document.querySelector('.advanced'),
                    searchPanel   = document.querySelector('.advanced-search').querySelector('.panel');

                advSearchTog.onclick = function (e) {
                    e.preventDefault();
                    advSearchOpen ? searchPanel.classList.remove('active') : searchPanel.classList.add('active');
                    advSearchOpen = !advSearchOpen;
                };
            }
        },
        // Project page
        'project'             : {
            init: function () {
                $('.match').matchHeight();

                $.breakpoint({
                    condition  : function () {
                        return matchMedia('only screen and (min-width:414px) and (max-width:850px)').matches;
                    },
                    first_enter: function () {
                    },
                    enter      : function () {
                        $('.project-detail').prepend($(".project-detail .right"));
                    },
                    exit       : function () {
                        $('.project-detail').prepend($(".project-detail .left"));
                    }
                });

                $.breakpoint({
                    condition  : function () {
                        return matchMedia('only screen and (max-width:414px)').matches;
                    },
                    first_enter: function () {
                    },
                    enter      : function () {
                        $('.project-detail').prepend($(".project-detail .right"));
                    },
                    exit       : function () {
                        $('.project-detail').prepend($(".project-detail .left"));
                    }
                });
            }
        },
        // Event page
        'event'               : {
            init: function () {

                $('form.event-form').append($("form.event-form #addguest"));

                //Event Booking Form Count Bookings
                $("#addguest").click(function () {
                    $('#guest_template').clone(true, true).insertBefore('input[name=nextEventGuestNumber]');
                    $('.guest').last().removeAttr("id");
                    $('.guest').last().removeAttr("style");
                    var nextTarget = "target_" + $('#nextEventGuestNumber').val();
                    $('.guest').last().addClass(nextTarget);
                    var newHTML = $('.guest').last().html().replace(/{no}/g, $('#nextEventGuestNumber').val());
                    $('.guest').last().html(newHTML);

                    $('.guest').last().append("<a class='removeguest more'>Remove</a>");

                    $("." + nextTarget + " input.check," + "." + nextTarget + " input.styled," + "." + nextTarget + " textarea," + "." + nextTarget + " select.styled").uniform();

                    $('#nextEventGuestNumber').val($('#nextEventGuestNumber').val() + 1);

                    var n = $('.guest').length;
                    $('button.grey span').text($('#nextEventGuestNumber').val() - 1);
                    $.fn.matchHeight._update();

                });

                $(".event-content-details").on("click", ".removeguest", function (event) {
                    $(this).closest('.guest').remove();
                    var n = $('.guest').length;
                    $('#nextEventGuestNumber').val($('#nextEventGuestNumber').val() - 1);
                    $('button.grey span').text($('#nextEventGuestNumber').val() - 1);
                });

                $("a.more:contains('Select all')").click(function () {
                    $(this).prev('dl').find('input').each(function () {
                        $(this).prop('checked', true);
                        $.uniform.update(this);
                    });
                });

                //Event Booking Form highlight Attendee
                $(".guest-check").on("change", function (event) {
                    if ($(".guest-check").is(":checked")) {
                        $(this).closest('.guest').addClass("fellow");
                        $(this).closest('.guest').children('h3').hide();
                        $(this).closest('.guest').prepend("<h3 class='fellow-title'>Fellow</h3>");
                    } else {
                        $(this).closest('.guest').removeClass("fellow");
                        $(this).closest('.guest').children('h3').show();
                        $(this).closest('.guest').children('.fellow-title').remove();
                    }
                });
            }
        },// Case Study Listings
        'case_study_category' : {
            init    : function () {
            },
            finalize: function () {
            }
        },// Search page
        'search_results'      : {
            init    : function () {
                $('.search-listing .article-image').imagefill();
            },
            finalize: function () {
                $('.search-listing article .txt').matchHeight();
            }
        },// Most viewed page
        'most_viewed'         : {
            init    : function () {
                $('.search-listing .article-image').imagefill();
            },
            finalize: function () {
                $('.search-listing article .txt').matchHeight();
            }
        },// Mentor Nomination Form
        'mentor_nominations'  : {
            init: function () {
                $(".formlist textarea").jqte({
                    format : false,
                    color  : false,
                    fsize  : false,
                    indent : false,
                    outdent: false,
                    ul     : false,
                    rule   : false,
                    sub    : false,
                    strike : false,
                    sup    : false,
                    source : false
                });
            }
        },// Mentor Nomination Form
        'evidence_form_2015'  : {
            init: function () {
                $(".formlist textarea").jqte({
                    format : false,
                    color  : false,
                    fsize  : false,
                    indent : false,
                    outdent: false,
                    ul     : false,
                    rule   : false,
                    sub    : false,
                    strike : false,
                    sup    : false,
                    source : false
                });
            }
        },// Mentor Nomination Form
        'evidence_form_2015_2': {
            init: function () {
                $(".formlist textarea").jqte({
                    format : false,
                    color  : false,
                    fsize  : false,
                    indent : false,
                    outdent: false,
                    ul     : false,
                    rule   : false,
                    sub    : false,
                    strike : false,
                    sup    : false,
                    source : false
                });
            }
        },// Order Form
        'order_form'          : {
            init: function () {
                $(".formlist textarea").jqte({
                    format : false,
                    color  : false,
                    fsize  : false,
                    indent : false,
                    outdent: false,
                    ul     : false,
                    rule   : false,
                    sub    : false,
                    strike : false,
                    sup    : false,
                    source : false
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire      : function (func, funcname, args) {
            var fire;
            var namespace = Ctrl;
            funcname      = (funcname === undefined) ? 'init' : funcname;
            fire          = func !== '';
            fire          = fire && namespace[func];
            fire          = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    // Custom Functions
    // These have been moved from the original script files
    /////////////////////////////////////////////////////////

    var emailReg  = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var numberReg = /[^a-zA-Z]$/;

    //Form Validation function
    $('.validate').click(function (e) {
        e.preventDefault();
        var error  = 0;
        var fields = $(this).closest('form').find('.required');

        fields.each(function (i, e) {
            var placeHolder = $(this).prop('defaultValue');

            if (!this.value || this.value === placeHolder || this.value === 'message') {
                $(this).addClass('error');
                error++;
            } else {
                $(this).removeClass('error');
            }

            if ($(this).hasClass('email-required') === true) {
                if (emailReg.test($(this).val()) === false) {
                    $(this).addClass('error');
                    error++;
                } else {
                    $(this).removeClass('error');
                }
            }

            if ($(this).hasClass('tel-required') === true) {
                if (numberReg.test($(this).val()) === false) {
                    $(this).addClass('error');
                    error++;
                } else {
                    $(this).removeClass('error');
                }
            }
        });

        if (!error) {
            $(this).closest('form').submit();
        }
    });

    //Well it's a random string function... for when random is needed?
    function randomString(length) {
        var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
        var str   = '';
        for (var i = 0; i < length; i++) {
            str += chars[Math.floor(Math.random() * chars.length)];
        }
        return str;
    }

    var rnd = randomString(0);

    //View all function for the publications page
    function viewallCheck() {
        $('a.viewall').each(function () {
            if ($('#categoryFilter').val().length > 0) {
                $(this).hide();
                if ($('#yearfilter').val().length > 0) {
                    $(this).show();
                }
            } else {
                $(this).show();
            }
        });
    }

})(jQuery); // Fully reference jQuery after this point.

